import React, { Suspense, useState } from 'react';
import Routes from './config/routes';
import './config/translation/index.js';

const App = () => {
  const [lang, setLang] = useState('IT');
  return (
    <Suspense fallback={null}>
        <Routes {...[lang, setLang]}/>
    </Suspense>
  );
}
export default App;