import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from  '../pages/home';
// import About from '../pages/about';
import Reseller from '../pages/reseller';
import Contact from '../pages/contact';

const MyRoutes = ({lang, setLang}) => (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home {...[lang, setLang]}/>}/>
            <Route path="reseller" element={<Reseller {...[lang, setLang]}/>}/>
            {/* <Route path="about" element={<About {...[lang, setLang]}/>}/> */}
            <Route path="contact" element={<Contact {...[lang, setLang]}/>}/>
        </Routes>
    </BrowserRouter>
);

export default MyRoutes