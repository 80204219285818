import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
// import { useTranslation } from 'react-i18next';
// import News from '../../components/news';
import "./style.css";

const Home = props => {
    // const { t } = useTranslation();
    //console.log(">>>>>", props)
    return (
        <>
        <Header menuSelected='home' abs white {...[props.lang, props.setLang]}/>
        <div className='home'>
            <img src={require('../../assets/home.jpeg')} alt="home" />
            <div className='banner'>
                <p>Distributore Ufficiale per la Svizzera</p>
                <img src={require('../../assets/lerbolario.jpg')} alt="erbolario" />
                <a href='https://www.instagram.com/erbolario_quartino/' target='_blank' rel="noreferrer" ><p>Scopri le novità!</p></a>
            </div>
            {/* <button onClick={()=>i18n.changeLanguage("en")}>change lang</button> */}
            {/* <News/> */}
            <br/><br/>
        </div>
        <Footer/>
        </>
    );
}

export default Home;