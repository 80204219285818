import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./style.css";

const Header = ({menuSelected, abs, white}) => {
    const { t/*, i18n*/ } = useTranslation();
    return (
        <div className={`header${white?"-white ":" "} ${abs ? 'divabsolute' : ''}`}>
            <div className={`logo${white?"-white":""}`}>
                <Link to={"/"}>PHYTO<span>SWISS</span> TRADE SA</Link>
            </div>
            <div className={`menu${white?"-white":""}`}>
                <Link to={"/"}><label className={`${menuSelected === 'home' ? `selected${white?"-white":""}` : ''}`}>{t("home")}</label></Link>
                <Link to={"/reseller"}><label className={`${menuSelected === 'reseller' ? 'selected' : ''}`}>{t("reseller")}</label></Link>
                {/* <Link to={"/about"}><label className={`${menuSelected === 'about' ? 'selected' : ''}`}>{t("about")}</label></Link> */}
                <Link to={"/contact"}><label className={`${menuSelected === 'contact' ? 'selected' : ''}`}>{t("contact")}</label></Link>
            </div>
        </div>
    );
}
export default Header