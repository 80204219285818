import React, { useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { Phone, LocationOn, Email, CheckSharp } from '@mui/icons-material';
import "./style.css";
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const mandatoryField = "Campo obbligatorio";
    const errorFoneDesc = "Telefono non valido";
    const validMailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [name, setName] = useState("");
    const [lastname, setLastName] = useState("");
    const [mail, setMail] = useState("");
    const [company, setCompany] = useState("");
    const [fone, setFone] = useState("");
    const [message, setMessage] = useState("");
    const [errorName, setErrorName] = useState(false);
    const [errorNameText, setErrorNameText] = useState("");
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorLastNameText, setErrorLastNameText] = useState("");
    const [errorMail, setErrorMail] = useState(false);
    const [errorMailText, setErrorMailText] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState("");
    const [errorFone, setErrorFone] = useState(false);
    const [errorFoneText, setErrorFoneText] = useState("");
    const [sentStatus, setSentStatus] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    return (
    <>
    <Header menuSelected='contact'/>
    <div className='contact-message'>
        <label className='contact-message-label'>Mandaci un <span>messaggio</span></label>
        <div className='contact-message-map'>
            <div>
                <Stack marginY={2} spacing={2} direction="row" justifyContent={"left"}>
                    <TextField 
                        id="outlined-basic"
                        label="Nome*"
                        variant="outlined"
                        style={{width:"50%"}}
                        value={name}
                        error={errorName}
                        helperText={errorNameText}
                        InputProps={{sx:{fontFamily: "Raleway"}}}
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        onChange={event=>{
                            setErrorName(false);
                            setErrorNameText("");
                            setName(event.target.value);
                        }} />
                    <TextField
                        id="outlined-basic" 
                        label="Cognome*" 
                        variant="outlined" 
                        InputProps={{sx:{fontFamily: "Raleway"}}}
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        style={{width:"50%"}} 
                        value={lastname}
                        error={errorLastName}
                        helperText={errorLastNameText}
                        onChange={event=>{
                            setErrorLastName(false);
                            setErrorLastNameText("");
                            setLastName(event.target.value);
                        }}/>
                </Stack>
                <Stack direction="column" spacing={2} alignItems="self-start">
                    <TextField 
                        id="outlined-basic" 
                        label="E-Mail*" 
                        variant="outlined" 
                        style={{width:"100%"}} 
                        InputProps={{sx:{fontFamily: "Raleway"}}}
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        value={mail}
                        error={errorMail}
                        helperText={errorMailText}
                        onChange={event=>{
                            setErrorMail(false);
                            setErrorMailText("");
                            setMail(event.target.value);
                        }}/>
                    </Stack>
                <Stack marginY={2} spacing={2} direction="row" justifyContent={"left"}>
                    <TextField
                        id="outlined-basic" 
                        label="Azienda (opzionale)" 
                        variant="outlined" 
                        InputProps={{sx:{fontFamily: "Raleway"}}}
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        style={{width:"50%"}}
                        value={company}
                        onChange={event=>{
                            setCompany(event.target.value);
                        }}/>
                    <TextField 
                        id="outlined-basic" 
                        label="Telefono (opzionale)" 
                        variant="outlined" 
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        style={{width:"50%"}}
                        value={fone}
                        error={errorFone}
                        helperText={errorFoneText}
                        placeholder='(555) 555 55 55'
                        InputProps={{
                            startAdornment: <InputAdornment sx={{fontFamily: "Raleway"}} position="start"><label>+41 </label></InputAdornment>,
                            sx:{fontFamily: "Raleway"}
                        }}
                        onFocus={()=>{
                            setFone(fone.replace(/\D/g, ''));
                        }}
                        onBlur={()=>{
                            if(fone.trim().length > 0 && fone.trim().length < 9){
                                setErrorFone(true);
                                setErrorFoneText(errorFoneDesc)
                                return;
                            }
                            if(fone.trim().length === 9){
                                let x = fone.replace(/\D/g, '').match(/(\d{2})(\d{3})(\d{2})(\d{2})/);
                                if(x) setFone('(' + x[1] + ') ' + x[2] + ' ' + x[3] + ' ' + x[4]);
                            } else if(fone.trim().length === 10){
                                let x = fone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
                                if(x) setFone('(' + x[1] + ') ' + x[2] + ' ' + x[3] + ' ' + x[4]);
                            }
                        }}
                        onChange={event=>{
                            if(event.target.value.trim().length > 10) return;
                            setFone(event.target.value.replace(/\D/g, ''));
                            setErrorFone(false);
                            setErrorFoneText("");
                        }}/>
                </Stack>
                <Stack direction="column" spacing={2} alignItems="self-start">
                    <TextField
                        rows={3} 
                        multiline 
                        id="outlined-basic" 
                        label="Messaggio*" 
                        variant="outlined" 
                        InputProps={{sx:{fontFamily: "Raleway"}}}
                        InputLabelProps={{sx:{fontFamily: "Raleway"}}}
                        style={{width:"100%"}}
                        value={message}
                        error={errorMessage}
                        helperText={errorMessageText}
                        onChange={event=>{
                            setErrorMessage(false);
                            setErrorMessageText("");
                            setMessage(event.target.value);
                        }}/>

                    <Stack marginY={2} spacing={2} direction="row" justifyContent={"left"}>
                        <Button onClick={()=>{
                            if(sentStatus){
                                setSnackbarText("Il tuo messaggio è già stato inviato");
                                setSnackbarSeverity("warning");
                                setSnackbarOpen(true);
                                return;
                            }
                            let error = false;
                            if(`${name}`.trim() === ""){
                                setErrorName(true);
                                setErrorNameText(mandatoryField);
                                error = true;
                            }
                            if(`${lastname}`.trim() === ""){
                                setErrorLastName(true);
                                setErrorLastNameText(mandatoryField);
                                error = true;
                            }
                            if(`${mail}`.trim() === ""){
                                setErrorMail(true);
                                setErrorMailText(mandatoryField);
                                error = true;
                            }
                            if(!error && !mail.match(validMailRegex)){
                                setErrorMail(true);
                                setErrorMailText("E-Mail non valido");
                                error = true;
                            }
                            if(`${message}`.trim() === ""){
                                setErrorMessage(true);
                                setErrorMessageText(mandatoryField);
                                error = true;
                            }
                            if(!error){

                                let templateParams = {
                                    contact: `Nome: ${name} ${lastname}\n`+
                                    `E-Mail: ${mail}\n`+
                                    `${ company ? "Azienda: " + company + "\n" : "" }`+
                                    `${ fone ? "Telefono: " + fone + "\n" : "" }`,
                                    message: message
                                };

                                setLoading(true);

                                emailjs
                                .send('service_v4jpk44', 'template_cv4wylh', templateParams, "pORskRBNYmdD7JYDO")
                                .then(()=> {
                                    setSentStatus(true);
                                    setSnackbarText("Messaggio inviato con successo!");
                                    setSnackbarSeverity("success");
                                    setSnackbarOpen(true);
                                    setLoading(false);
                                    setName("");setLastName("");setMail("");setCompany("");setFone("");setMessage("");
                                })
                                .catch(() => {
                                    setSentStatus(false);
                                    setSnackbarText("Impossibile inviare il messaggio. Riprova.");
                                    setSnackbarSeverity("error");
                                    setSnackbarOpen(true);
                                    setLoading(false);
                                });
                            }
                        }} disableElevation variant="contained" color="success" sx={{fontFamily: "Raleway"}}>Invia</Button>
                        {sentStatus && 
                            <div style={{display: "flex",alignItems: 'center'}}>
                            <CheckSharp fontSize='large' sx={{color: "#158D2F"}}/> 
                            <label>Messaggio inviato con successo</label> 
                            </div>
                        }
                    </Stack>
                </Stack>
            </div>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d177055.87199940116!2d8.81975885336933!3d46.10098289027517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4785cbd710566007%3A0xf7c7abfcee23a4d1!2sPhytoswiss%20Trade%20SA!5e0!3m2!1sen!2sch!4v1686126432029!5m2!1sen!2sch"
                title='mapa'
                width="500" 
                height="82%" 
                style={{border:1}}
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

        </div>
        <label className='contact-message-label'>I nostri <span>contatti</span></label>
        <p>Phytoswiss Trade SA</p>
        <div className='contact'>
            <LocationOn />
            <div>
                <label><b>Sede legale:</b> Quartiere Maghetti 20, 6900 Lugano, CH</label>
                <label><b>Sede logistica:</b> Via in Paes 84, 6572 Quartino, CH</label>
            </div>
        </div>
        <div className='phone'>
            <Phone/>
            <label>+41 (0)91 780 40 16</label>
        </div>
        <div className='phone phone-last'>
            <Email/>
            <label><a href='mailto:info@phytoswisstrade.ch'>info@phytoswisstrade.ch</a> </label>
        </div>
    </div>    
    <Footer/>
    <Snackbar anchorOrigin={{ vertical: 'center', horizontal: 'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={()=>setSnackbarOpen(false)} >
        <Alert severity={snackbarSeverity}>{snackbarText}</Alert>
    </Snackbar>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
);
}
export default Contact;