import React, { useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import resellers from './data.json'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import "./style.css";
import { Phone, LocationOn } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Reseller = () => {
    const [canton, setCanton] = useState('ALL');
    return (
        <>
        <Header menuSelected='reseller'/>
            <div className='reseller'>
                <div className='work-with-us'>
                    <label>Volete diventare un <span>rivenditore ufficiale Erbolario?</span></label>
                    <div>
                    {/* <p>
                        Nata a Lodi nel 1978 dall'entusiasmo di Franco Bergamaschi e Daniela Villa, come piccola erboristeria artigiana, 
                        anno dopo anno L'Erbolario è cresciuta fino a diventare un'eccellenza nel mercato italiano della cosmesi di 
                        derivazione vegetale, senza mai abbandonare la propria vocazione di Impresa 100% made in Italy.
                        Oggi il marchio è rappresentato da circa <span>5000 Punti Vendita in Italia</span> e <span>195 Negozi franchising</span>, di cui 16 
                        all'estero, ed è distribuito in <span>45 Paesi nel mondo</span>.
                    </p> */}
                    <p>
                        Se siete interessati a una possibile partnership nel territorio svizzero e a contribuire a promuovere prodotti 
                        ecologici che rispettano la salute e l'ambiente, siamo a vostra completa disposizione per rispondere a tutte 
                        le vostre domande! Possiamo fissare un appuntamento nel nostro centro logistico a Quartino o direttamente nella 
                        vostra sede, dove potrete provare personalmente la qualità dei prodotti Erbolario. <Link to="/contact"> Contattaci! </Link>
                        <div>Nadia De Felice</div>
                    </p>
                    </div>
                </div>
                <Stack marginY={10} spacing={2} direction="row" justifyContent={"center"}>
                    <Button onClick={()=>setCanton('ALL')} disableElevation variant={canton === 'ALL' ? "contained" : "outlined"} color="success">TUTTI ({resellers.length})</Button>
                    <Button onClick={()=>setCanton('TI')} disableElevation variant={canton === 'TI' ? "contained" : "outlined"} color="success">SVIZZERA ITALIANA ({resellers.filter(value=>value.canton==="TI").length})</Button>
                    <Button onClick={()=>setCanton('DE')} disableElevation variant={canton === 'DE' ? "contained" : "outlined"} color="success">SVIZZERA TEDESCA ({resellers.filter(value=>value.canton==="DE").length})</Button>
                    <Button onClick={()=>setCanton('FR')} disableElevation variant={canton === 'FR' ? "contained" : "outlined"} color="success">SVIZZERA FRANCESE ({resellers.filter(value=>value.canton==="FR").length})</Button>
                </Stack>
                <div className='reseller-cards'>
                    {resellers
                    .filter(value => {
                        if(canton === 'ALL') return true;
                        return value.canton === canton;
                    })
                    .sort((a,b)=>a.city.split(" ")[1].localeCompare(b.city.split(" ")[1]))
                    .map(item=>(
                    <div className='reseller-card'>
                        <div className='reseller-card-header'>{item.company}</div>
                        <div className='reseller-card-body'>
                            <div>
                                <LocationOn/>
                                <div>
                                    <label>{item.addr}</label>
                                    <label>{item.city}</label>
                                </div>
                            </div>
                            <div>
                                <Phone/>
                                <label>{item.phone}</label>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                
            </div>
        <Footer/>
        </>
    );
}
export default Reseller;