import React from 'react';
import { LocationOn, Instagram } from '@mui/icons-material';
import "./style.css";

const Footer = () => (
    <div className='footer'>
        <label>Via in Paes 84, 6572 Quartino <span>|</span> <a href='mailto:info@phytoswisstrade.ch' target={'_blank'} rel="noreferrer">info@phytoswisstrade.ch</a> <span>|</span> +41 (0)91 780 40 16</label>
        <div>
            <a href='https://goo.gl/maps/QoTaJZHJWCcqVjQv6' target={'_blank'} rel="noreferrer">
                <LocationOn fontSize='large' sx={{color: '#FFF'}}/>
            </a>
            <a href='https://www.instagram.com/erbolario_quartino/' target={'_blank'} rel="noreferrer">
                <Instagram fontSize='large' sx={{color: '#FFF'}}/>
            </a>
        </div>
    </div>
);

export default Footer;